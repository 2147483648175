.literary {
    background-color: black;
    color: white;
    text-align: center;
    min-height: 100vh; /* Ensures the background covers the full viewport height */
    padding: 20px; /* Adds some padding for better aesthetics */
  }
  
  .poem-title, .pdf-link {
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-decoration: underline;
    margin-bottom: 10px;
  }
  
  .poem-content {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #333; /* Darker background for better contrast */
    display: inline-block;
    text-align: center;
  }
  
  .pdf-viewer {
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    background-color: #333; /* Darker background for better contrast */
    max-width: 100%;
    display: inline-block;
  }
  
  .pdf-viewer {
    height: 600px;
    overflow-y: auto;
  }
  
  .pdf-navigation {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .pdf-navigation button {
    margin: 0 5px;
  }
  