body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  .container {
    padding: 20px;
    margin-left: 20%;
    margin-right: 20%;
    max-width: 80%;
  }
  
  h2 {
    margin-bottom: 10px;
  }
  
  p {
    margin: 10px 0;
    line-height: 1.8;
  }
  