.art-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center; /* Center text */
  color: white; /* Set text color to white */
  background-color: black; /* Set background color to black */
}


.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));  /* Size of images */
  gap: 10px;
}

.image-grid img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

