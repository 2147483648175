body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  .container {
    padding: 20px;
    margin-left: 20%;
    margin-right: 20%;
    max-width: 80%;
  }
  .img {
    width: 60%;
    height: auto;
    object-fit: contain;
  }
  
  h2 {
    margin-bottom: 10px;
  }
  
  p {
    margin: 10px 0;
    line-height: 1.8;
    font-size: 1.1em;
  }
  
  a {
    color: #0066cc;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  button {
    background-color: #0066cc;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #004d99;
  }
  
  .resume-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .resume-modal iframe {
    width: 80%;
    height: 80%;
    border: none;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: red;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .close-button:hover {
    background-color: darkred;
  }
  