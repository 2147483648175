  
  .header-right {
    display: flex;
    gap: 20px; /* Increased gap */
  }
  
  .header-right a {
    color: white;
    text-decoration: none;
    font-size: 1.30em; /* Increased font size */
  }
  
  .header-right a:hover {
    text-decoration: underline;
  }
  

  body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .App-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #282c34;
    padding: 0px 30px;
    color: white;
  }
  
  .header-left {
    display: flex;
    align-items: center;
  }
  
  .profile-image img {
    width: 75px;
    height: 75px;
    border-radius: 30%;
    margin-right: 15px;
    object-fit: cover;
  }
  
  .header-right a {
    color: white;
    margin-left: 20px;
    text-decoration: none;
  }
  
  .header-right a:hover {
    text-decoration: underline;
  }
  
  .App-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #282c34;
    padding: 20px;
    color: white;
    margin-top: auto;
  }
  
  .App-footer a {
    color: white;
    margin: 0 15px;
    text-decoration: none;
  }
  
  .App-footer a:hover {
    text-decoration: underline;
  }
  